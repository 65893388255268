import * as React from "react";
import { Modal } from "@material-ui/core";
import SCButton from "../buttons/SCButton";
import { navigate } from "gatsby-link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import firebase from "firebase";
import config from "../../config";

const baseUri = config.serverUri;

const PerfilForm = ({ show, onClose, ispwaComponent }) => {
  const profileUri = baseUri + "/users";
  const [ispwa, setIspwa] = React.useState(false);
  const [form, setForm] = React.useState(false);

  const [error, setError] = React.useState("");
  const [questions, setQuestions] = React.useState([]);
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [selectedValues, setSelectedValues] = React.useState(Array(questions.length).fill(""));

  React.useEffect(() => {
    if (ispwaComponent !== undefined) {
      setIspwa(ispwaComponent);
    }

    // Obtener las preguntas del endpoint
    fetch(`${baseUri}/preguntas/cuestionario`)
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        setSelectedValues(Array(data.length).fill(""));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ispwaComponent]);

  const next = async () => {
    if (selectedValues[currentQuestion] !== "") {
      setError(null);
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        await updatePreference();

        setTimeout(() => {
          setCurrentQuestion(0);
          setForm(false);
          onClose();
          navigate(`/`);
        }, 500);
      }
    } else {
      setError("Selecciona una opción");
    }
  };

  const skip = async () => {
    await updatePreference({});

    setTimeout(() => {
      setCurrentQuestion(0);
      setForm(false);
      onClose();
      navigate(`/`);
    }, 500);
  };

  const handleChange = (event, index) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = event.target.value;
    setSelectedValues(newSelectedValues);
  };

  const controlProps = (item) => ({
    checked: selectedValues[currentQuestion] === item,
    onChange: (event) => handleChange(event, currentQuestion),
    value: item,
    inputProps: { "aria-label": item },
  });

  const updatePreference = async (updateData) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          fetch(`${profileUri}/?email=${user.email}`)
            .then((response) => {
              response.json().then((data) => {
                const id = data[0]._id;
                const preferencesArray = [];

                // Recorre las preguntas y registra los números de las respuestas seleccionadas
                questions.forEach((question, index) => {
                  const selectedOption = selectedValues[index];
                  const selectedOptionIndex = question.respuestas.findIndex(
                    (respuesta) => respuesta.contenido === selectedOption
                  );

                  // Verificamos si la respuesta fue seleccionada
                  if (selectedOptionIndex !== -1) {
                    preferencesArray.push({
                      pregunta: index + 1,
                      respuesta: selectedOptionIndex + 1,
                    });
                  }
                });

                const requestBody = {
                  idUsuario: id,
                  preferencias: preferencesArray,
                  ...updateData, // Agregar datos de actualización, como { contestado: true } o { omitido: true }
                };

                if (data[0].suscription === "premium") {
                  fetch(config.serverUri + `/users/guardar-preferencias`, {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json; charset=utf-8",
                    },
                  })
                  .then((response) => response.json())
                  .then((responseData) => {
                    console.log("Preferencias guardadas y usuario actualizado:", responseData);
                  })
                  .catch((error) => {
                    console.log("Error al guardar preferencias:", error);
                  });
                } else {
                  setSelectedValues(Array(questions.length).fill(""));
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <div
        className={
          !ispwa
            ? "h-screen flex items-center overflow-y-auto"
            : "w-full h-screen flex justify-center items-center "
        }
        style={!ispwa ? null : { backgroundColor: "#C4C4C440" }}
      >
        <div
          className={
            !ispwa
              ? "realtive bg-white rounded-lg px-5 pt-8 pb-4 overflow-y-auto md:overflow-y-auto mx-auto"
              : "h-auto bg-white rounded-xl mx-3 px-5 py-4 "
          }
          style={!ispwa ? { maxHeight: "85%", maxWidth: 450 } : null}
        >
          {!form ? (
            <>
              <main>
                <div className="flex items-center justify-between h-12 mb-2">
                  <h3 className="text-sc-black mt-5" style={{ fontSize: 20 }}>
                    ¡Eres Shifter Premium!
                  </h3>
                  <button onClick={skip}>Omitir</button>
                </div>
                <hr className="h-1" style={{ backgroundColor: "#6B6B6B" }}></hr>
                <div className="w-full mb-8">
                  <p>
                    Disfruta de todos los beneficios que tenemos para ti.
                    ¡Empieza ahora!
                  </p>
                </div>
                <div className="w-full flex flex-col justify-center items-center">
                  <div
                    className="my-8 shadow-md rounded-full"
                    style={{ width: 250 }}
                  >
                    <SCButton onClick={() => setForm(true)}>
                      <b>Continuar</b>
                    </SCButton>
                  </div>
                </div>
              </main>
              <footer></footer>
            </>
          ) : (
            <>
              <main>
                <div className="flex items-center justify-center h-12 mb-2">
                  <h3 className="text-sc-black mt-5" style={{ fontSize: 20 }}>
                    Cuestionario de gustos
                  </h3>
                </div>
                <hr className="h-1" style={{ backgroundColor: "#6B6B6B" }}></hr>
                <div className="w-full mb-8">
                  <p className="text-center">
                    AYÚDANOS A CONOCER MÁS SOBRE TI PARA ACOMPAÑARTE A LOGRAR TUS
                    OBJETIVOS
                  </p>
                  <p>Para ofrecerte una mejor experiencia, responde las siguientes preguntas</p>
                </div>
                <div>
                  {questions[currentQuestion] ? (
                    <>
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-3 text-lg">{`${questions[currentQuestion].titulo} ${currentQuestion + 1}/${questions.length}`}</FormLabel>
                        <RadioGroup
                          aria-label="preferences"
                          value={selectedValues[currentQuestion]}
                          onChange={(event) => handleChange(event, currentQuestion)}
                        >
                          {questions[currentQuestion].respuestas.map((respuesta) => (
                            <FormControlLabel
                              key={respuesta._id}
                              value={respuesta.contenido}
                              control={
                                <Radio
                                  {...controlProps(respuesta.contenido)}
                                  style={{ color: "#00CEA9" }}
                                />
                              }
                              label={respuesta.contenido}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <div className="w-full flex flex-col justify-center items-center">
                        <p className="-mb-2 text-red-500">{error ?? null}</p>
                        <div
                          className="my-8 shadow-md rounded-full"
                          style={{ width: 250 }}
                        >
                          <button className="w-full" type="reset">
                            <SCButton onClick={next}>
                              <b>Siguiente</b>
                            </SCButton>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </main>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PerfilForm;
